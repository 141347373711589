// ag-grid.scss defines structural styles and should be imported first
@import "../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-grid.scss";
@import "../../../node_modules/@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham/sass/ag-theme-balham-mixin.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
  font-family: arial;
}

a {
  text-decoration: underline;
  color: #142bad;
}

#root {
  height: 100%;
}

.ag-theme-custom {
  @include ag-theme-balham((
    header-foreground-color: #5d5d5d,
    header-background-color: #e9effb,
    border-color: #cccccc,
  ));

  .ag-menu-option-active {
    background-color: #2661ad;
    color: white;
  }

  .ag-header-cell-text {
    font-weight: bold;
  }
}
